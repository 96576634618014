import { Controller } from "@hotwired/stimulus";
import { Sidebar } from "@coreui/coreui-pro";

export default class extends Controller {
  static targets = [
    "sidebar",
    "aside",
    "lightThemeButton",
    "darkThemeButton",
    "bodyElement",
  ];

  connect() {
    if (this.bodyElementTarget.classList.contains("dark-theme")) {
      this.darkThemeButtonTarget.checked = true;
    } else {
      this.lightThemeButtonTarget.checked = true;
    }

    let current_path = window.location.pathname;
    let selector = `[data-link-path='${current_path}']`;
    let target = document.querySelector(selector);

    if (target) {
      let closestUl = target.closest("ul");

      if (closestUl.classList.contains("nav-group-items")) {
        let navGroup = target.closest(".nav-group");
        navGroup.classList.add("show");
        target.classList.add("active");
      } else if (closestUl.classList.contains("sidebar-nav")) {
        target.classList.add("active");
      }
    }
  }

  disconnect() {}

  handleThemeChange(event) {
    if (event.target.value === "light") {
      document.body.classList.remove("dark-theme");
    }
    if (event.target.value === "dark") {
      document.body.classList.add("dark-theme");
    }
  }

  toggleSidebar() {
    const sidebar = new Sidebar(this.sidebarTarget);
    sidebar.toggle();
  }

  toggleSidebarFold() {
    if (this.sidebarTarget.classList.contains("sidebar-narrow-unfoldable")) {
      this.sidebarTarget.classList.remove("sidebar-narrow-unfoldable");
    } else {
      this.sidebarTarget.classList.add("sidebar-narrow-unfoldable");
    }
  }

  navGroupToggle(event) {
    event.preventDefault();
    let toggler = event.target;
    let navGroup = toggler.closest("li");

    if (navGroup.classList.contains("show")) {
      navGroup.classList.remove("show");
    } else {
      navGroup.classList.add("show");
    }
  }
}
