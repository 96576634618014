import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["paymentConfirmationForm", "autoReturnForm"];
  static values = { wait: Number }

  connect() {
    if (this.hasPaymentConfirmationFormTarget) {
      setTimeout(() => this.paymentConfirmationFormTarget.submit(), 1500);
    }else if(this.hasAutoReturnFormTarget){
      setTimeout(() => this.autoReturnFormTarget.submit(), this.waitValue);
    }
  }

  disconnect() {}
}
